import { NoHydration } from 'solid-js/web';
import { BreadcrumbItem, Button, Container, Heading, HorizontalRule, Link, Page, Section } from '@troon/ui';
import { createAsync, useLocation } from '@solidjs/router';
import { Title, Meta } from '@solidjs/meta';
import { Hero } from '../../../components/hero/photo';
import { getConfigValue } from '../../../modules/config';
import { FacilitiesByGrouping } from '../../../components/facility/by-grouping';
import { PictureSection } from '../../../components/layouts/picture-section';
import { getFacilities } from './_data';
import type { RouteDefinition } from '@solidjs/router';

export default function PrivePage() {
	const loc = useLocation();
	const data = createAsync(() => getFacilities(), { deferStream: true });

	return (
		<>
			<Title>Private Club Amenities | Troon Privé Member Programs | Troon</Title>
			<Meta
				name="description"
				content="In addition to golf, Full Members enjoy amenities at participating Troon Privé clubs, with access to
							fitness, tennis, aquatics and/or dining."
			/>

			<NoHydration>
				<Hero
					src={`${getConfigValue('IMAGE_HOST')}/web/hero/prive-amenities.jpg`}
					breadcrumbs={() => (
						<>
							<BreadcrumbItem href="/member-programs">Troon Privé Privileges</BreadcrumbItem>
							<BreadcrumbItem href={loc.pathname}>Private Club Amenities</BreadcrumbItem>
						</>
					)}
				>
					<Heading as="h1">Private Club Amenities</Heading>
					<p class="text-lg">
						In addition to golf, Full Members enjoy amenities at participating Troon Privé clubs, with access to
						fitness, tennis, aquatics and/or dining.
					</p>
				</Hero>
			</NoHydration>

			<Container>
				<Page>
					<PictureSection src={`${getConfigValue('IMAGE_HOST')}/web/hero/amenities-feature.jpeg`} loading="eager">
						<NoHydration>
							<Heading as="h2">Overview</Heading>
							<p>
								In addition to golf, Full Members enjoy amenities at participating Troon Privé clubs, with access to
								fitness, tennis, aquatics and/or dining. Simply pick out a destination from the participating facilities
								and fill out our amenities request form with your member information.
							</p>

							<p>
								For <em>Visa Infinite Members</em> seeking to request a tee time, please utilize the designated site US
								Cardholders or Canada Cardholders.
							</p>
							<p class="flex items-start gap-4">
								<Button as={Link} href="/member-programs/private-club-amenities/request-amenities" class="grow-0">
									Request a reservation
								</Button>
								<Button as={Link} href="#participating-clubs" appearance="secondary" class="grow-0">
									Participating clubs
								</Button>
							</p>
						</NoHydration>
					</PictureSection>

					<HorizontalRule />

					<Section id="participating-clubs">
						<Heading as="h2" class="mx-auto max-w-4xl text-center">
							Participating Clubs
						</Heading>
						<FacilitiesByGrouping facilities={data()?.facilities.facilities ?? []} />
					</Section>
				</Page>
			</Container>
		</>
	);
}

export const route = { info: { nav: { hero: true } } } satisfies RouteDefinition;
